import mock from 'src/utils/mock';

mock.onGet('/api/contents/filters/revcontent').reply(() => {
  const filters = [
    {
      name: "enabled",
      label: "Enabled",
      options: [
        {
          id: 'all',
          name: 'Both'
        },
        {
          id: 'active',
          name: 'On'
        },
        {
          id: 'inactive',
          name: 'Off'
        },
      ]
    },
    {
      name: "admin_status",
      label: "Admin Status",
      options: [
        {
          id: 'all',
          name: 'All'
        },
        {
          id: 'approved',
          name: 'Approved'
        },
        {
          id: 'denied',
          name: 'Denied'
        },
        {
          id: 'pending',
          name: 'Pending'
        },
      ]
    }];

  return [200, { filters }];
});

mock.onGet('/api/contents/columns/revcontent').reply(() => {
  const columns = [
      {
        name: "id",
        label: "ID",
      },
      {
        name: "headline",
        label: "Headline",
      },
      {
        name: "image_url",
        label: "Image",
        format: "image",
      },
      {
        name: "enabled",
        label: "Enabled",
      },
      {
        name: "admin_status",
        label: "Admin Status",
      },
      {
        name: "cost",
        label: "Cost",
        format: "currency",
        align: "right",
      },
      {
        name: "clicks",
        label: "Clicks",
        format: "number",
        align: "right",
      },
      {
        name: "target_url",
        label: "Target URL",
        format: "url",
      },
  ];

  return [200, { columns }];
});

mock
  .onGet('/api/contents/filters/mgid')
  .reply(() => {
    const filters = [{
      name: "status",
      label: "Status",
      multiple: true,
      options: [{
        id: 'active',
        name: 'Active'
      }, {
        id: 'paused',
        name: 'Paused'
      }, {
        id: 'blocked',
        name: 'Blocked'
      }, {
        id: 'onModeration',
        name: 'On Moderation'
      }]
    }];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/mgid')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/engageya')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/engageya')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right"
    }, {
      name: "views",
      label: "Views",
      format: "number",
      align: "right"
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    }, {
      name: "revenue",
      label: "Revenue",
      format: "currency",
      align: "right"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/propellerads')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/propellerads')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "description",
      label: "Description"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "approval_status",
      label: "Approval Status"
    }, {
      name: "frequency",
      label: "Frequency",
      format: "number",
      align: "right",
    }, {
      name: "capping",
      label: "Capping",
      format: "number",
      align: "right",
    },];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/voluum')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/voluum')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "approval_status",
      label: "Approval Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right",
    }, {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right",
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right",
    }, {
      name: "profit",
      label: "Profit",
      format: "currency",
      align: "right",
    },];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/rtx')
  .reply(() => {
    const filters = [{
      name: "status",
      label: "Status",
      multiple: true,
      options: [{
        id: 'active',
        name: 'Active'
      }, {
        id: 'paused',
        name: 'Paused'
      }, {
        id: 'archived',
        name: 'Archived'
      }]
    }, ];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/rtx')
  .reply(() => {
    const columns = [{
      name: "adgroup_id",
      label: "AdGroup ID"
    }, {
      name: "adgroup_name",
      label: "AdGroup Name"
    }, {
      name: "id",
      label: "ID"
    }, {
      name: "name",
      label: "Name"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "approval",
      label: "Approval Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right",
    }, {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right",
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right",
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right",
    }, {
      name: "profit",
      label: "Profit",
      format: "currency",
      align: "right",
    },];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/zeropark')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/zeropark')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "description",
      label: "Description"
    }, {
      name: "state",
      label: "State"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right",
    }, {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right",
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right",
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right",
    },];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/selfadvertiser')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/selfadvertiser')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "description",
      label: "Description"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right",
    }, {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right",
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right",
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right",
    },];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/admaven')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/admaven')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "enabled",
      label: "Enabled",
    }, {
      name: "status",
      label: "Status"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/contents/filters/richads')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/richads')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "description",
      label: "Description",
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right",
    },  {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right",
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right",
    }, {
      name: "conversions",
      label: "Conversions",
      format: "percent",
      align: "right",
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right",
    }];

    return [200, {
      columns
    }];
  });

  mock
  .onGet('/api/contents/filters/jubna')
  .reply(() => {
    const filters = [{
      name: "status",
      label: "Status",
      options: [{
        id: 'all',
        name: 'All'
      }, {
        id: 'active',
        name: 'Active'
      }, {
        id: 'inactive',
        name: 'Inactive'
      }]
    }];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/contents/columns/jubna')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "headline",
      label: "Headline"
    }, {
      name: "image_url",
      label: "Image",
      format: "image",
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right",
    },  {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right",
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right",
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right",
    }];

    return [200, {
      columns
    }];
  });




