import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axiosNova';

const initialState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {
      if(action.payload.success){
        state.notifications = action.payload.data;
      }else{
        state.notifications = [];
      }
    }
  }
});

export const reducer = slice.reducer;

export const getNotifications = () => async (dispatch) => {
  const response = await axios.get(`/api/notifications/log`);
  dispatch(slice.actions.getNotifications(response.data));
};

export default slice;
