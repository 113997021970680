import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axiosNova';

const initialState = {
  balances: [],
  spents: [],
  revenues: [],
  avg_spents: [],
};

const slice = createSlice({
  name: 'finance',
  initialState,
  reducers: {
    getSummary(state, action) {
      const { data } = action.payload;
      state.balances = data.balances;
      state.spents = data.spents;
      state.revenues = data.revenues;
      state.avg_spents = data.avg_spents
    },
  }
});

export const reducer = slice.reducer;

export const getSummary = () => async (dispatch) => {
  const response = await axios.get('/api/dashboard/accountSummary');

  dispatch(slice.actions.getSummary(response.data));
};


export default slice;
