import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  getUserList: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  const updatePassword = async (password) => {
    return firebase.auth().currentUser.updatePassword(password);
  };

  const getUserList = async () => {
    var docs = firebase.firestore().collection("users");
    return docs.get().then(function(docRefs) {
      var data = [];
      docRefs.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    }).catch(function(error) {
        console.log("Error getting document:", error);
        return [];
    });
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        
        // check custom claim to not allow anyone to register and login
        user.getIdToken(true).then(()=>{
          user.getIdTokenResult()
            .then((idTokenResult) => {
              if(idTokenResult.claims.admin || idTokenResult.claims.accessLevel > 0){
                dispatch({
                  type: 'AUTH_STATE_CHANGED',
                  payload: {
                    isAuthenticated: true,
                    user: {
                      id: user.uid,
                      avatar: user.photoURL,
                      email: user.email,
                      name: user.displayName || user.email,
                      token: idTokenResult,
                      tier: idTokenResult.claims.tier || 'admin',
                      accessLevel: idTokenResult.claims.accessLevel,
                      position: user.position || 'Member'
                    }
                  }
                });
              }else{
                dispatch({
                  type: 'AUTH_STATE_CHANGED',
                  payload: {
                    isAuthenticated: false,
                    user: null
                  }
                });
              }
            })
            .catch((error) => {
              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                  isAuthenticated: false,
                  user: null
                }
              });
            });
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
        updatePassword,
        getUserList,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
