import axios from 'axios';
import firebase from 'src/lib/firebase';
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async config => {
  const token = await firebase.auth().currentUser.getIdToken();
  config.headers['X-Authorization'] =  `Bearer ${token}`;
  return config;
}, (error) => {
  return Promise.reject(error)
})
axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;

export default axiosInstance;
