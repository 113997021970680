import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axiosNova';

const initialState = {
  topCampaigns: [],
  metricsTable: [],
};

const slice = createSlice({
  name: 'thrive',
  initialState,
  reducers: {
    getTopcampaigns(state, action) {
      const { data } = action.payload;
      state.topCampaigns = data;
    },
    getMetricsTable(state, action) {
      const { data } = action.payload;
      state.metricsTable = data.data;
    },
  }
});

export const reducer = slice.reducer;

export const getTopcampaigns = () => async (dispatch) => {
  const response = await axios.get('/api/thrive/campaigns/getTopCamps');

  var data = {
    data: [],
    success: false,
  };
  if(response.data.success){
    data = response.data;
  }

  dispatch(slice.actions.getTopcampaigns(data));
};

export const getMetricsTable = () => async (dispatch) => {
  const response = await axios.get('/api/thrive/campaigns/getMetricsTable');

  var data = {
    data: {data: []},
    success: false,
  };
  if(response.data.success){
    data = response.data;
  }

  dispatch(slice.actions.getMetricsTable(data));
};


export default slice;
