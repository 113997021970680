import mock from 'src/utils/mock';

mock
  .onGet('/api/campaigns/sources')
  .reply(() => {
    const sources = {
      "revcontent": {
        id: "revcontent",
        name: "RevContent",
        accounts: {
          "BeBetterAd": {
            id: "BeBetterAd", 
            name: "BeBetterAd",
          },
          "121Marketing_2": {
            id: "121Marketing_2", 
            name: "121Marketing_2",
          }
        },
      },
      "mgid": {
        id: "mgid",
        name: "MGID",
        accounts: {
          "findfun99": {
            id: "findfun99", 
            name: "findfun99",
          },
        },
      },
      "engageya": {
        id: "engageya",
        name: "EngageYa",
        accounts: {
          "bebetter": {
            id: "bebetter", 
            name: "bebetter",
          },
        },
      },
      "propellerads": {
        id: "propellerads",
        name: "PropellerAds",
        accounts: {
          "joshua": {
            id: "joshua", 
            name: "joshua",
          },
        },
      },
      "voluum": {
        id: "voluum",
        name: "VoluumDSP",
        accounts: {
          "joshua": {
            id: "joshua", 
            name: "joshua",
          },
        },
      },
      "rtx": {
        id: "rtx",
        name: "RTX",
        accounts: {
          "joshua": {
            id: "joshua", 
            name: "joshua",
          },
        },
      },
      "zeropark": {
        id: "zeropark",
        name: "ZeroPark",
        accounts: {
          "joshua": {
            id: "joshua", 
            name: "joshua",
          },
        },
      },
      "selfadvertiser": {
        id: "selfadvertiser",
        name: "SelfAdvertiser",
        accounts: {
          "joshua": {
            id: "joshua", 
            name: "joshua",
          },
        },
      },
      "admaven": {
        id: "admaven",
        name: "AdMaven",
        accounts: {
          "BeBetter": {
            id: "BeBetter", 
            name: "BeBetter",
          },
        },
      },
      "richads": {
        id: "richads",
        name: "RichAds",
        accounts: {
          "joshua": {
            id: "joshua", 
            name: "joshua",
          },
        },
      },
      "jubna": {
        id: "jubna",
        name: "Jubna",
        accounts: {
          "joshua": {
            id: "joshua", 
            name: "joshua",
          },
        },
      },
      "taboola": {
        id: "taboola",
        name: "Taboola",
        accounts: {
          "josh": {
            id: "josh", 
            name: "josh",
          },
        },
      }
    };
    return [200, {
      sources
    }];
  });


mock
  .onGet('/api/campaigns/filters/revcontent')
  .reply(() => {
    const filters = [{
      name: "enabled",
      label: "Enabled",
      options: [{
        id: 'all',
        name: 'Both'
      }, {
        id: 'active',
        name: 'On'
      }, {
        id: 'inactive',
        name: 'Off'
      }]
    }, {
      name: "status",
      label: "Status",
      options: [{
        id: 'all',
        name: 'All'
      }, {
        id: 'active',
        name: 'Active'
      }, {
        id: 'inactive',
        name: 'Inactive'
      }, {
        id: 'disabled',
        name: 'Disabled'
      }, {
        id: 'archived',
        name: 'Archived'
      }]
    }];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/campaigns/columns/revcontent')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "name",
      label: "Name"
    }, {
      name: "enabled",
      label: "Enabled"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right"
    }, {
      name: "default_bid",
      label: "Default Bid",
      format: "currency",
      align: "right"
    }, {
      name: "budget",
      label: "Budget",
      format: "currency",
      align: "right"
    }, {
      name: "start_date",
      label: "Start Date",
      align: "right"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/campaigns/filters/mgid')
  .reply(() => {
    const filters = [{
      name: "status",
      label: "Status",
      options: [{
        id: 'all',
        name: 'All'
      }, {
        id: 'active',
        name: 'Active'
      }, {
        id: 'paused',
        name: 'Paused'
      }]
    }];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/campaigns/columns/mgid')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "name",
      label: "Name"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    },{
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right"
    },{
      name: "revenue",
      label: "Revenue",
      format: "currency",
      align: "right"
    }, {
      name: "start_date",
      label: "Start Date",
      align: "right"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/campaigns/filters/engageya')
  .reply(() => {
    const filters = [];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/campaigns/columns/engageya')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "name",
      label: "Name"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/campaigns/filters/propellerads')
  .reply(() => {
    const filters = [{
      name: "status",
      label: "Status",
      multiple: true,
      options: [{
        id: 1,
        name: 'Draft'
      }, {
        id: 2,
        name: 'Pending'
      }, {
        id: 3,
        name: 'Rejected'
      }, {
        id: 4,
        name: 'Ready'
      }, {
        id: 5,
        name: 'Test'
      }, {
        id: 6,
        name: 'Working'
      }, {
        id: 7,
        name: 'Paused'
      }, {
        id: 8,
        name: 'Stopped'
      }, {
        id: 9,
        name: 'Completed'
      }]
    }, {
      name: "archived",
      label: "Archived",
      options: [{
        id: 'all',
        name: 'Both'
      }, {
        id: 0,
        name: 'No'
      }, {
        id: 1,
        name: 'Yes'
      }]
    }];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/campaigns/columns/propellerads')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "name",
      label: "Name"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    }, {
      name: "revenue",
      label: "Revenue",
      format: "currency",
      align: "right"
    }, {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right"
    }, {
      name: "archived",
      label: "Archived"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/campaigns/filters/voluum')
  .reply(() => {
    const filters = [{
      name: "include",
      label: "Include",
      options: [{
        id: 'ALL',
        name: 'All'
      }, {
        id: 'ACTIVE',
        name: 'Active'
      }, {
        id: 'ARCHIVED',
        name: 'Archived'
      }, {
        id: 'TRAFFIC',
        name: 'Traffic'
      }, {
        id: 'ENTITY',
        name: 'Entiny'
      }]
    }];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/campaigns/columns/voluum')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "name",
      label: "Name"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    }, {
      name: "revenue",
      label: "Revenue",
      format: "currency",
      align: "right"
    }, {
      name: "ctr",
      label: "CTR",
      format: "percent",
      align: "right"
    }, {
      name: "start_date",
      label: "Start Date",
      align: "right"
    }];

    return [200, {
      columns
    }];
  });

mock
  .onGet('/api/campaigns/filters/rtx')
  .reply(() => {
    const filters = [{
      name: "status",
      label: "Status",
      multiple: true,
      options: [{
        id: 'active',
        name: 'Active'
      }, {
        id: 'paused',
        name: 'Paused'
      }, {
        id: 'archived',
        name: 'Archived'
      }]
    }, ];

    return [200, {
      filters
    }];
  });

mock
  .onGet('/api/campaigns/columns/rtx')
  .reply(() => {
    const columns = [{
      name: "id",
      label: "ID"
    }, {
      name: "name",
      label: "Name"
    }, {
      name: "status",
      label: "Status"
    }, {
      name: "clicks",
      label: "Clicks",
      format: "number",
      align: "right"
    }, {
      name: "cost",
      label: "Cost",
      format: "currency",
      align: "right"
    }, {
      name: "revenue",
      label: "Revenue",
      format: "currency",
      align: "right"
    }, {
      name: "impressions",
      label: "Impressions",
      format: "number",
      align: "right"
    }];

    return [200, {
      columns
    }];
  });

  
mock
.onGet('/api/campaigns/filters/zeropark')
.reply(() => {
  const filters = [{
    name: "status",
    label: "Status",
    multiple: true,
    options: [{
      id: 'PENDING',
      name: 'Pending'
    }, {
      id: 'ACTIVE',
      name: 'Active'
    }, {
      id: 'PAUSED',
      name: 'Paused'
    }, {
      id: 'DRAFT',
      name: 'Draft'
    }, {
      id: 'REJECTED',
      name: 'Rejected'
    },  {
      id: 'ARCHIVED',
      name: 'Archived'
    }, {
      id: 'DELETED',
      name: 'Deleted'
    }]
  }];

  return [200, {
    filters
  }];
});

mock
.onGet('/api/campaigns/columns/zeropark')
.reply(() => {
  const columns = [{
    name: "id",
    label: "ID"
  }, {
    name: "name",
    label: "Name"
  }, {
    name: "status",
    label: "State"
  }, {
    name: "clicks",
    label: "Clicks",
    format: "number",
    align: "right"
  }, {
    name: "cost",
    label: "Cost",
    format: "currency",
    align: "right"
  }, {
    name: "revenue",
    label: "Revenue",
    format: "currency",
    align: "right"
  }, {
    name: "impressions",
    label: "Impressions",
    format: "number",
    align: "right"
  }];

  return [200, {
    columns
  }];
});

  
mock
.onGet('/api/campaigns/filters/selfadvertiser')
.reply(() => {
  const filters = [{
    name: "status",
    label: "Status",
    options: [{
      id: 'all',
      name: 'All'
    }, {
      id: 'Pending',
      name: 'Pending'
    }, {
      id: 'Running',
      name: 'Running'
    }, {
      id: 'Paused',
      name: 'Paused'
    }, {
      id: 'Rejected',
      name: 'Rejected'
    },  {
      id: 'Removed',
      name: 'Removed'
    }, {
      id: 'Draft',
      name: 'Draft'
    }]
  }];

  return [200, {
    filters
  }];
});

mock
.onGet('/api/campaigns/columns/selfadvertiser')
.reply(() => {
  const columns = [{
    name: "id",
    label: "ID"
  }, {
    name: "name",
    label: "Name"
  }, {
    name: "status",
    label: "Status"
  }, {
    name: "clicks",
    label: "Clicks",
    format: "number",
    align: "right"
  }, {
    name: "cost",
    label: "Cost",
    format: "currency",
    align: "right"
  }, {
    name: "impressions",
    label: "Impressions",
    format: "number",
    align: "right"
  }];

  return [200, {
    columns
  }];
});


mock
.onGet('/api/campaigns/filters/admaven')
.reply(() => {
  const filters = [{
    name: "enabled",
    label: "Enabled",
    options: [{
      id: 'all',
      name: 'All'
    }, {
      id: 1,
      name: 'Active'
    }, {
      id: 0,
      name: 'Inactive'
    }]
  }];

  return [200, {
    filters
  }];
});

mock
.onGet('/api/campaigns/columns/admaven')
.reply(() => {
  const columns = [{
    name: "id",
    label: "ID"
  }, {
    name: "name",
    label: "Name"
  }, {
    name: "enabled",
    label: "Enabled"
  }, {
    name: "clicks",
    label: "Clicks",
    format: "number",
    align: "right"
  }, {
    name: "cost",
    label: "Cost",
    format: "currency",
    align: "right"
  }, {
    name: "impressions",
    label: "Impressions",
    format: "number",
    align: "right"
  }, {
    name: "ctr",
    label: "CTR",
    format: "percent",
    align: "right"
  }];

  return [200, {
    columns
  }];
});


mock
.onGet('/api/campaigns/filters/richads')
.reply(() => {
  const filters = [{
    name: "status",
    label: "Status",
    options: [{
      id: 'all',
      name: 'All'
    }, {
      id: 1,
      name: 'Active'
    }, {
      id: 0,
      name: 'Inactive'
    }]
  }];

  return [200, {
    filters
  }];
});

mock
.onGet('/api/campaigns/columns/richads')
.reply(() => {
  const columns = [{
    name: "id",
    label: "ID"
  }, {
    name: "name",
    label: "Name"
  }, {
    name: "status",
    label: "Status"
  }, {
    name: "clicks",
    label: "Clicks",
    format: "number",
    align: "right"
  }, {
    name: "cost",
    label: "Cost",
    format: "currency",
    align: "right"
  }, {
    name: "impressions",
    label: "Impressions",
    format: "number",
    align: "right"
  }];

  return [200, {
    columns
  }];
});


mock
.onGet('/api/campaigns/filters/jubna')
.reply(() => {
  const filters = [{
    name: "status",
    label: "Status",
    options: [{
      id: 'all',
      name: 'All'
    }, {
      id: 'active',
      name: 'Active'
    }, {
      id: 'inactive',
      name: 'Inactive'
    }]
  }];

  return [200, {
    filters
  }];
});

mock
.onGet('/api/campaigns/columns/jubna')
.reply(() => {
  const columns = [{
    name: "id",
    label: "ID"
  }, {
    name: "name",
    label: "Name"
  }, {
    name: "status",
    label: "Status"
  }, {
    name: "clicks",
    label: "Clicks",
    format: "number",
    align: "right"
  }, {
    name: "cost",
    label: "Cost",
    format: "currency",
    align: "right"
  }, {
    name: "impressions",
    label: "Impressions",
    format: "number",
    align: "right"
  }, {
    name: "ctr",
    label: "CTR",
    format: "percent",
    align: "right"
  }];

  return [200, {
    columns
  }];
});









