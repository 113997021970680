import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Link,
  Divider,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import FirebaseAuthLogin from 'src/views/auth/LoginView/FirebaseAuthLogin';

const methodIcons = {
  'Auth0': '/static/images/auth0.svg',
  'FirebaseAuth': '/static/images/firebase.svg',
  'JWT': '/static/images/jwt.svg'
};

const useStyles = makeStyles((theme) => ({
  root: {
  },
  banner: {
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  cardContent: {
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { method } = useAuth();

  return (
    <Page
      className={classes.root}
      title="Nova App - Be Better Advertising"
    >
      <Container
        className={classes.cardContainer}
        maxWidth="sm"
      >
        <Card>
          <CardContent className={classes.cardContent}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h2"
                >
                  Sign in
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  Sign in on the internal platform
                </Typography>
              </div>
              <div className={classes.currentMethodIcon}>
                <img
                  alt="Auth method"
                  src={methodIcons[method]}
                />
              </div>
            </Box>
            <Box
              flexGrow={1}
              mt={3}
            >
              <FirebaseAuthLogin />
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Link
              href="https://bebetteradvertising.com"
              variant="body2"
              color="textSecondary"
              target="_blank"
            >
              BeBetterAdvertising.com
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default LoginView;
