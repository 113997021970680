import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axiosNova';

const initialState = {
  users: []
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers(state, action) {
      if(action.payload.success){
        state.users = action.payload.data;
      }else{
        state.users = [];
      }
    }
  }
});

export const reducer = slice.reducer;

export const getUsers = () => async (dispatch) => {
  const response = await axios.get(`/api/users`);
  dispatch(slice.actions.getUsers(response.data));
};

export default slice;
